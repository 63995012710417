import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/adoption-application/sections"
import banner from "../images/foster-banner-bg.jpg"
const AdoptionApplicationPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Adoption Application" />
    <InnerBanner theme="white" image={banner} title="Adoption Application" />
    <Sections.Section1 />
    <HowToHelp />
  </Layout>
}

export default AdoptionApplicationPage