import "../../styles/application-sections.css"

import React, {
    forwardRef,
    Fragment,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react"

import axios from "axios"
import { navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import {
    Controller,
    useForm,
} from "react-hook-form"
import ReactInputMask from "react-input-mask"

import { PlusIcon } from "@heroicons/react/solid"

import ApplicationAccordion from "../../components/shared/application-accordion"
import { API_BASE_URL } from "../../constants/config"
import {
    ageRangePreferences,
    colorPreferences,
    defaultCurrentDogs,
    defaultMonths,
    defaultPastDogs,
    dwellTypes,
    emailFormat,
    genders,
    insideOutside5050,
    ownRent,
    phoneFormat,
    phoneFormatField,
    phoneTypes,
    unwillingBehaviors,
    yesNo,
    zipFormat,
    zipFormatField,
} from "../../constants/defaults"
import { parseError } from "../../constants/utility"
import {
    alertInitialState,
    alertReducer,
} from "../../reducers/alert-reducer"
import Alert from "../shared/Alert"
import InlineLoader from "../shared/inline-loader"
import RegisterTab from "../shared/register-tabs"

const MultipleFields = forwardRef(({ title, data, fields, onUpdateField, onAdd, onRemove, classCols, is_optional, addLabel }, ref) => {
    const { formState: { errors }, trigger, control, } = useForm();
    useImperativeHandle(ref, () => ({

        validate: async () => {
            return await trigger();
        }

    }));



    return <Fragment>
        <ApplicationAccordion is_optional={is_optional} onRemove={(index) => { onRemove(index) }}>

            {data && data.map((item, fkey) => {
                return <div key={fkey} title={`${title} #${fkey + 1}`}>
                    <div className={`form-fields ${classCols}`}>
                        {fields && fields.map((field, key) => {
                            var field_key = field.key;
                            var s_field = field;
                            return <div key={"l" + key} className={`field-group ${field.className}`}>
                                <label htmlFor={field.key}>{field.label !== "" ? field.label : <span>&nbsp;</span>} {field.required === true && field.label !== "" && <span className="req">*</span>}</label>


                                {field.type === 'text' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}

                                    render={({ field: { onChange } }) => (
                                        <input
                                            {...field}
                                            onChange={(e) => {
                                                onUpdateField(fkey, field_key, e.target.value);
                                                onChange(e);
                                            }}
                                            type="text"
                                            value={item[field_key]}
                                        />
                                    )}
                                />}
                                {field.type === 'textarea' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}

                                    render={({ field: { onChange } }) => (
                                        <textarea
                                            {...field}
                                            onChange={(e) => {
                                                onUpdateField(fkey, field_key, e.target.value);
                                                onChange(e);
                                            }}
                                            resize="none"
                                            value={item[field_key]}
                                        />
                                    )}
                                />}
                                {field.type === 'number' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}

                                    render={({ field: { onChange } }) => (
                                        <input
                                            {...field}
                                            onKeyDown={(e) => {

                                                //fix firefox
                                                if (e.keyCode === 190 && String(e.target.value).indexOf('.') > -1) {
                                                    e.preventDefault();
                                                }

                                                if ((e.keyCode >= 48 && e.keyCode <= 57) || [190, 8].indexOf(e.keyCode) > -1) {
                                                    console.log(e.keyCode)
                                                }
                                                else {
                                                    e.preventDefault();
                                                }

                                            }}
                                            onChange={(e) => {
                                                onUpdateField(fkey, field_key, e.target.value);
                                                onChange(e);
                                            }}
                                            min="0"
                                            type="text"
                                            value={item[field_key]}
                                        />
                                    )}
                                />}


                                {field.type === 'select' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required }}
                                    defaultValue={item[field_key]}
                                    render={({ field: { onChange } }) => (

                                        <select {...field} value={item[field_key]} onChange={(e) => {
                                            onUpdateField(fkey, field_key, e.target.value);
                                            onChange(e);
                                        }}>
                                            {s_field.placeholder === true && <option value="">Select</option>}
                                            {s_field.data_source && s_field.data_source.map((s, k) => {
                                                return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                            })}
                                        </select>
                                    )}
                                />}



                                {field.type === 'phone' && <Controller
                                    control={control}
                                    name={`${fkey}_${field.key}`}
                                    rules={{ required: field.required, pattern: field.pattern }}
                                    render={({ field: { onChange } }) => (

                                        <ReactInputMask   {...field} onChange={(e) => {
                                            onUpdateField(fkey, field_key, e.target.value);
                                            onChange(e);
                                        }}

                                            type={"text"} value={item[field_key]} mask={phoneFormatField} />
                                    )}
                                />}
                                {field.type === 'radio' && (field.key.indexOf('gender') > -1) && <div className="flex md:flex-row">
                                    {field.data_source && field.data_source.map((v, key) => {
                                        return v !== 'Either' && <label className="mr-3 force-font-normal" key={"v" + key}>

                                            <Controller
                                                control={control}
                                                name={`${fkey}_${field.key}`}
                                                rules={{ required: field.required }}
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        onChange={(e) => {
                                                            onUpdateField(fkey, field_key, e.target.value);
                                                            onChange(e);
                                                        }}
                                                        checked={v === item[field_key]} value={v}
                                                    />
                                                )}
                                            /> {v}</label>
                                    })}
                                </div>}

                                {field.type === 'radio' && (field.key.indexOf('gender') === -1) && <div className="flex md:flex-row">
                                    {field.data_source && field.data_source.map((v, key) => {
                                        return v !== 'Either' && <label className="mr-3 force-font-normal" key={"v" + key}>


                                            <Controller
                                                control={control}
                                                name={`${fkey}_${field.key}`}
                                                rules={{ required: field.required }}
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        onChange={(e) => {
                                                            onUpdateField(fkey, field_key, e.target.value);
                                                            onChange(e);
                                                        }}
                                                        checked={String(v.value) === item[field_key]} value={v.value}
                                                    />
                                                )}
                                            /> {v.label}</label>
                                    })}
                                </div>}

                                {errors[`${fkey}_${field.key}`] && errors[`${fkey}_${field.key}`].type === 'required' && <span className="text-red-600">This field is required</span>}
                                {errors[`${fkey}_${field.key}`] && errors[`${fkey}_${field.key}`].type === 'pattern' && field.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                {errors[`${fkey}_${field.key}`] && errors[`${fkey}_${field.key}`].type === 'pattern' && field.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}

                            </div>
                        })}
                    </div>
                </div>
            })}

        </ApplicationAccordion>
        <button onClick={onAdd} className="btn-accent btn-addmore-pets" type="button"><PlusIcon className="w-5 h-5 mr-2" /> {addLabel ? addLabel : "Add"}</button>
    </Fragment>

});

const Section1 = () => {
    const [states, setStates] = useState([]);


    const [currentDogs, setCurrentDogs] = useState([defaultCurrentDogs]);
    const [pastDogs, setPastDogs] = useState([defaultPastDogs]);

    const [busy, setBusy] = useState(false);
    const [alertState, dispatchAlert] = useReducer(alertReducer, alertInitialState);
    const { register, formState: { errors }, watch, trigger, getValues, setValue } = useForm();
    const currentDogsRef = useRef();
    const pastDogsRef = useRef();
    const { executeRecaptcha } = useGoogleReCaptcha();


    const start_info_fields = useMemo(() => ([
        { label: "What is your main reason for wanting to adopt a Rescued Labrador?", key: "main_reason", type: "textarea", required: true, className: 'col-span-2' },
        { label: "Why do you think you could offer a good home for a Rescued Labrador?", key: "good_home", type: "textarea", required: true, className: 'col-span-2' },
        { label: "I have read and understand this rescue's ", link: <a href='/adoption/#intro' target="_blank">Adoption Process</a>, key: "understand_adoption", type: "checkbox", required: true, className: 'col-span-2' },
        { label: "I meet this rescue's ", link: <a href='/adoption/#rfh' target="_blank">Requirements of a Forever Home</a>, key: "i_meet", type: "checkbox", required: true, className: 'col-span-2' },
        { label: "I will allow a rescue representative to conduct a Home Visit", key: "i_will_allow", type: "checkbox", required: true, className: 'col-span-2' },
        { label: "I understand the application fee of $27.50 is non-refundable", key: "understand_application", desc: "This donation is used to offset medical expenses not covered by adoption fees", type: "checkbox", required: true, className: 'col-span-2' },
    ]), [])
    const personal_info_fields = useMemo(() => ([
        { label: "First Name", key: "first_name", type: "text", required: true, className: 'half' },
        { label: "Last Name", key: "last_name", type: "text", required: true, className: 'half' },
        { label: "Street Address", key: "street_address", type: "text", required: true, className: 'w-full' },
        { label: "City", key: "city", type: "text", required: true, className: 'one-third' },
        { label: "State", key: "state", type: "select", required: true, className: 'one-third', data_source: states, placeholder: true },
        { label: "Zipcode", key: "zipcode", type: "zip", required: true, pattern: zipFormat, className: 'one-third' },
        { label: "Primary Phone", key: "primary_phone_type", type: "select", required: true, className: 'half', data_source: phoneTypes },
        { label: "", pattern: phoneFormat, key: "primary_phone", type: "phone", required: true, className: 'half' },
        { label: "Alternative Phone", key: "alternative_phone_type", type: "select", required: false, className: 'half', data_source: phoneTypes },
        { label: "", pattern: phoneFormat, key: "alternative_phone", type: "phone", required: false, className: 'half' },
        { label: "Email Address", pattern: emailFormat, key: "email", type: "email", required: true, className: 'half', desc: "We communicate primarily through email" },

    ]), [states]);



    const coApplicants_fields = useMemo(() => ([
        { label: "First Name", key: "co_app_first_name", type: "text", required: false, className: 'col-span-3 md:col-span-1' },
        { label: "Last Name", key: "co_app_last_name", type: "text", required: false, className: 'col-span-3 md:col-span-1' },
        { label: "Primary Phone", key: "co_app_primary_phone_type", data_source: phoneTypes, type: "select", required: false, className: 'col-span-3 md:col-span-1' },
        { label: "", key: "co_app_primary_phone", pattern: phoneFormat, type: "phone", required: false, className: 'col-span-3 md:col-span-1' },
        { label: "Alternative Phone", key: "co_app_alternative_phone_type", data_source: phoneTypes, type: "select", required: false, className: 'col-span-3 md:col-span-1' },
        { label: "", key: "co_app_alternative_phone", pattern: phoneFormat, type: "phone", required: false, className: 'col-span-3 md:col-span-1' },

    ]), []);

    const vet_info_fields = useMemo(() => ([
        { label: "Veterinary Clinic", key: "vet_clinic_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Veterinarian", key: "vet_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Office Phone", key: "vet_phone", type: "phone", pattern: phoneFormat, required: true, className: 'col-span-3 md:col-span-1' },
        { label: "What other names or phone numbers may your dog(s) be listed under at this vet? Please list any additional vets or relevant information.", key: "vet_listed_dog_names", type: "textarea", required: false, className: 'col-span-3' },
    ]), []);


    const current_dogs_fields = useMemo(() => ([
        { label: "Name", key: "curr_dog_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Breed", key: "curr_dog_breed", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Age (years)", key: "curr_dog_age", type: "number", min: 0, required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Gender", key: "curr_dog_gender", type: "radio", required: true, data_source: genders, className: 'col-span-3' },
        { label: "Spayed or Neutered?", key: "curr_dog_spayed_neutered", type: "radio", required: true, data_source: yesNo, className: 'col-span-3' },
        { label: "Current on vaccinations?", key: "curr_dog_vaccinations", type: "radio", required: true, data_source: yesNo, className: 'col-span-31' },
        { label: "Current on Heartworm Prevention?", key: "curr_dog_heartworm", type: "radio", required: true, data_source: yesNo, className: 'col-span-3' },
        { label: "Is the dog kept inside or outside?", key: "curr_dog_inside_outside", type: "radio", required: true, data_source: insideOutside5050, className: 'col-span-31' },

    ]), []);
    const past_dogs_fields = useMemo(() => ([
        { label: "Name", key: "past_dog_name", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Breed", key: "past_dog_breed", type: "text", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Age Reached (years)", key: "past_dog_age_reached", type: "number", required: true, className: 'col-span-3 md:col-span-1' },
        { label: "Gender", key: "past_dog_gender", type: "radio", required: true, data_source: genders, className: 'col-span-3' },
        { label: "What happened?", key: "past_dog_what_happen", type: "textarea", required: true, className: 'col-span-3' },


    ]), []);



    const environment_fields = useMemo(() => ([
        { label: "Do you rent or own your dwelling?", key: "own_rent", type: "select", required: true, data_source: ownRent, className: 'col-span-2' },
        { label: "If you rent, do you have your landlord's permission to have a large dog (75+ lbs)? ", key: "permission_to_have_large", type: "radio", required: true, data_source: yesNo, className: 'col-span-2' },
        { label: "Is your dwelling a house, townhouse, condo, apartment or other?", key: "dwelling_type", type: "select", required: true, data_source: dwellTypes, className: 'col-span-2' },
        { label: "Additional dwelling information (if you selected Other, please explain)", key: "other_dwell_type", type: "text", required: false, className: 'col-span-2' },
        { label: "What kind of fence does your yard have? (wood, chain link, etc.)", key: "kind_of_fence", type: "text", required: false, className: 'col-span-2 md:col-span-1' },
        { label: "How tall is your fence?", key: "tall_of_fence", type: "text", required: false, className: 'col-span-2 md:col-span-1' },
        { label: "Do you own an in-ground swimming pool?", key: "swimming_pool", type: "radio", required: true, data_source: yesNo, className: 'col-span-2' },
        { label: "If yes, is it fenced off separately from the yard?", key: "fenced_off_separately", type: "radio", required: true, data_source: yesNo, className: 'col-span-2' },
        { label: "How many adults?", key: "how_many_adults", type: "number", required: true, className: 'col-span-2 md:col-span-1' },
        { label: "How many children?", key: "how_many_children", type: "number", required: true, className: 'col-span-2 md:col-span-1' },
        { label: "Please list the ages of the children", key: "list_ages_children", type: "text", required: true, className: 'col-span-2' },
        { label: "Is there anything else about you or your family that we should be aware of?", key: "special_needs_family", type: "text", required: false, className: 'col-span-2' },
        { label: "Is anyone home during the day?", key: "anyone_home_during", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
        { label: "If so, whom", key: "anyone_home_during_whom", type: "text", required: false, className: 'col-span-2' },
        { label: "Where will the dog be kept during the day? Please explain.", key: "kept_during_day", type: "text", required: true, className: 'col-span-2' },
        { label: "How long each day will the dog be left alone?", key: "how_long_each_weekday", type: "text", required: true, className: 'col-span-2' },
        { label: "If you want a puppy, what is your plan for frequent potty breaks?", key: "frequent_potty_breaks", type: "text", required: true, className: 'col-span-2' },
        { label: "Where will the dog sleep at night?", key: "sleep_at_night", type: "text", required: true, className: 'col-span-2' },
        { label: "Where will the dog stay if you and your family go out of town?", key: "dog_stay_out_town", type: "text", required: true, className: 'col-span-2' },
        { label: "How will the dog be transported to the vet?", key: "transported_vet", type: "text", required: true, className: 'col-span-2' },
        { label: "If you no longer can or want to care for the dog, what will happen to the dog?", key: "tired_or_no_care", type: "text", required: true, className: 'col-span-2' },
        { label: "Who wants the dog the most?", key: "who_wants_dogs", type: "text", required: true, className: 'col-span-2' },
        { label: "Who will be the primary caretaker?", key: "primary_caretaker", type: "text", required: true, className: 'col-span-2' },
        { label: "How much time will the dog spend in exercising and training per day?", key: "training_per_day", type: "text", required: true, className: 'col-span-2' },
        { label: "Have you ever completed an obedience class with your previous or current dogs?", key: "ever_completed_class", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
        { label: "Are you willing to participate in an obedience class with your rescue Lab?", key: "willing_participate_class", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
        { label: "Have you ever used a crate with any of the dogs you have owned?", key: "own_create", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
        { label: "Are you willing to use a crate with your Rescued Labrador?", key: "willing_use_create", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
    ]), []);

    const dog_criteria_fields = useMemo(() => ([
        { label: "Age Range Preference(s)", key: "age_range_preference", type: "checkbox", data_source: ageRangePreferences, required: true, className: 'col-span-2' },
        { label: "Gender Preference(s)", key: "gender_preference", type: "checkbox", data_source: genders.slice(0, 2), required: true, className: 'col-span-2' },
        { label: "Color Preference(s)", key: "color_preference", type: "checkbox", data_source: colorPreferences, required: true, className: 'col-span-2' },
        { label: "Are you willing to adopt a Rescued Labrador with a medical condition?", key: "willing_to_adopt", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
        { label: "Are you flexible with your preferences above?", key: "flexible_preference", type: "radio", data_source: yesNo, required: true, className: 'col-span-2' },
        { label: "Additional Preferences", key: "age_range_preference_other", type: "textarea", required: false, className: 'col-span-2' },
        { label: "NO DOG IS PERFECT! Please tell us what dog behaviors you are unwilling or unable to try and work through. Check all that apply. If there is one we have missed please describe under Other.", key: "unwilling_behaviors", type: "checkbox", data_source: unwillingBehaviors, required: false, className: 'col-span-2' },
        { label: "If other, please specify.", key: "unwilling_behaviors_other", type: "text", required: true, className: 'col-span-2' },
        { label: "Describe the types of activities and exercise you will do with your adopted dog", key: "types_of_activities_exercise", type: "text", required: true, className: 'col-span-2' },
    ]), []);



    const finish_and_pay_fields = useMemo(() => ([
        { label: "Is there any additional information you'd like us to know about your application to adopt?", key: "additional_application_to_adopt", type: "text", required: false, className: 'col-span-3' },
        { label: "Card Holder Name", key: "card_holder_name", type: "text", required: true, className: 'col-span-3' },
        { label: "Card Number", key: "card_number", type: "text", required: true, className: 'col-span-3' },
        { label: "Month", key: "card_months", type: "select", data_source: defaultMonths, required: true, className: 'col-span-3 md:col-span-1', placeholder: true },
        { label: "Year", key: "card_year", type: "select", required: true, className: 'col-span-3 md:col-span-1', placeholder: true },
        { label: "Security Code", key: "card_cvv", type: "text", required: true, className: 'col-span-3 md:col-span-1' },

    ]), []);

    const swimming_pool = watch("swimming_pool")


    const renderYears = () => {
        var yearFrom = (new Date()).getFullYear();
        var years = [];
        for (var m = yearFrom; m <= yearFrom + 20; m++) {
            years.push(m);
        }
        return years.map((year, key) => {
            return <option key={key} value={year}>{year}</option>
        })
    }


    useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/static/states").then(({ data }) => {
                setStates(data.data);
            })

        }

        setTimeout(() => {
            setValue("state", "TX");
        }, 1000)


        // debug 


        // setValue("main_reason", "I'm test good main reason");
        // setValue("good_home", "I'm test good for home");
        // setValue("understand_adoption", true);
        // setValue("i_meet", true);
        // setValue("i_will_allow", true);
        // setValue("understand_application", true);

        // setValue("first_name", "Shawn");
        // setValue("last_name", "Byrne");
        // setValue("street_address", "Wall Street");
        // setValue("city", "Phoenix");

        // setValue("state", "AZ");
        // setValue("zipcode", "85006");
        // setValue("primary_phone_type", "Mobile");
        // setValue("primary_phone", "(502) 332-3232");
        // // setValue("alternative_phone_type", "Work");
        // // setValue("alternative_phone", "(502) 333-4444");
        // setValue("email", "natmbndev2@gmail.com");


        // setValue("co_app_first_name", "Elon")
        // setValue("co_app_last_name", "Musk")
        // setValue("co_app_primary_phone_type", "Mobile")
        // setValue("co_app_primary_phone", "(502) 233-4444")
        // setValue("co_app_alternative_phone_type", "Home")
        // setValue("co_app_alternative_phone", "(502) 333-5555")

        // setValue("rehomed_dog", '0');
        // //setValue("rehomed_dog_why", 'Rehome why');
        // setValue("have_vet", '0');
        // // setValue("last_time_saw_vet", 'yesterday');
        // // setValue("contact_vet", '1');
        // // setValue("vet_clinic_name", 'Petropolis');
        // // setValue("vet_name", 'Bill Gates');
        // // setValue("vet_phone", '(502) 782-4411');
        // // setValue("vet_listed_dog_names", 'stark, pepper, sherlock, suzie, penny, tokyo, chrysler');
        // setValue("type_of_heartworm", 'heart worm test');
        // setValue("brand_of_dog_food", 'pedigree');

        // // setCurrentDogs([{
        // //     curr_dog_name: "Stark",
        // //     curr_dog_breed: "Pom",
        // //     curr_dog_age: "8",
        // //     curr_dog_gender: "Male",
        // //     curr_dog_spayed_neutered: "0",
        // //     curr_dog_inside_outside: "Inside",
        // // }])

        // // setValue("other_pets", 'other pets test');


        // // setPastDogs([{
        // //     past_dog_name: "Lily",
        // //     past_dog_breed: "Pom",
        // //     past_dog_gender: "Female",
        // //     past_dog_age_reached: "0.2",
        // //     past_dog_what_happen: "Accident",
        // // }])
        // setValue("own_rent", 'Rent');
        // setValue("permission_to_have_large", '1');
        // setValue("dwelling_type", 'House');

        // //setValue("other_dwell_type", 'other dwelling test');

        // setValue("kind_of_fence", 'Wood');
        // setValue("tall_of_fence", '5ft');
        // setValue("swimming_pool", '1');
        // setValue("fenced_off_separately", '1');
        // setValue("how_many_adults", '2');
        // setValue("how_many_children", '7');
        // setValue("list_ages_children", '2,3,5,6,7,8');

        // // setValue("special_needs_family", 'special needs family');

        // setValue("anyone_home_during", '0');
        // //   setValue("anyone_home_during_whom", 'Me');

        // setValue("kept_during_day", 'living room');
        // setValue("how_long_each_weekday", 'never');
        // setValue("frequent_potty_breaks", 'anytime');
        // setValue("sleep_at_night", 'my room');
        // setValue("dog_stay_out_town", 'to my relatives');
        // setValue("transported_vet", 'Car');
        // setValue("tired_or_no_care", 'never tired');
        // setValue("who_wants_dogs", 'both of us');
        // setValue("primary_caretaker", 'Jeff Bezos');
        // setValue("training_per_day", '2 hours');
        // setValue("ever_completed_class", '1');
        // setValue("willing_participate_class", '1');
        // setValue("own_create", '1');
        // setValue("willing_use_create", '1');
        // setValue("age_range_preference", ['< 6 months', '6 months - 1 year']);
        // setValue("gender_preference", ['Male', 'Female']);
        // setValue("color_preference", ['Black', 'Yellow']);
        // setValue("willing_to_adopt", '1');
        // setValue("flexible_preference", '1');
        // setValue("unwilling_behaviors", ["Thunderstorm anxiety"]);

        // //setValue("unwilling_behaviors_other", 'Noisy');

        // setValue("types_of_activities_exercise", 'Walking');

        // // setValue("additional_application_to_adopt", 'No additional adoption');

        // setValue("card_holder_name", 'Shawn Byrne');
        // setValue("card_number", '4111111111111111');
        // setValue("card_months", '04');
        // setValue("card_year", '2024');
        // setValue("card_cvv", '900');



        return () => {
            m = false;
        }
    }, [])



    const have_vet = watch("have_vet");
    const rehomed_dog = watch("rehomed_dog");



    const unwilling_behaviors = watch("unwilling_behaviors");
    const own_rent = watch("own_rent");
    const how_many_children = watch("how_many_children");
    const have_current_dogs = watch("have_current_dogs");
    const have_past_dogs = watch("have_past_dogs");



    const onSubmit = async (data) => {

        if (busy) {

            return;
        }
        // if (!executeRecaptcha) {

        //     return;
        // }

        dispatchAlert({ type: 'reset' });
        setBusy(true);
        if (have_current_dogs === '0') {
            setCurrentDogs([]);
        }
        if (have_past_dogs === '0') {
            setPastDogs([]);
        }

        // return new Promise((resolve) => {
        //     setTimeout(async () => {

        //         resolve()
        //     }, 3000);
        // });
        const token_result = await axios.get(API_BASE_URL + "/website/application/get-token");
        const { data: token_data } = token_result;

        data = { ...data, current_dogs: currentDogs, past_dogs: pastDogs, token: token_data.data._id }
        axios.post(API_BASE_URL + "/website/application/adoption", data).then(({ data }) => {

            navigate("/thank-you-adoption-application");

        }).catch(({ response }) => {
            setBusy(false);
            if (response) {
                var { data } = response;
                window.scroll({ top: document.querySelector(".app-page-sec").offsetTop - 200, behavior: 'smooth' })
                dispatchAlert({ type: 'error', text: parseError(data) })
            }
        })
    }



    const validateField = async (index) => {


        var fields = [];
        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop - 200, behavior: 'smooth' })
        start_info_fields.forEach((item, key) => {
            fields.push(item.key);
        })

        var error = 0;
        var success = false;

        if (index >= 0) {
            success = await trigger(fields);
            error += success ? 0 : 1;

        }

        if (index >= 1) {
            fields = [];
            personal_info_fields.forEach((item, key) => {
                fields.push(item.key);
            })

            success = await trigger(fields);
            error += success ? 0 : 1;


        }
        if (index >= 2) {
            fields = ['rehomed_dog', 'rehomed_dog_why', 'have_vet', 'last_time_saw_vet', 'contact_vet', 'type_of_heartworm', 'brand_of_dog_food', 'other_pets', 'have_current_dogs', 'have_past_dogs'];

            success = await trigger(fields);
            error += success ? 0 : 1;

            vet_info_fields.forEach((item, key) => {
                fields.push(item.key);
            })

            success = await trigger(fields);
            error += success ? 0 : 1;
            if (have_current_dogs === '1') {
                success = await currentDogsRef.current.validate();
                error += success ? 0 : 1;
            }
            if (have_past_dogs === '1') {
                success = await pastDogsRef.current.validate();
                error += success ? 0 : 1;
            }
        }

        if (index >= 3) {
            fields = [];
            environment_fields.forEach((item, key) => {
                fields.push(item.key);
            });
            success = await trigger(fields);
            error += success ? 0 : 1;

        }

        if (index >= 4) {
            fields = [];
            dog_criteria_fields.forEach((item, key) => {
                fields.push(item.key);
            });
            success = await trigger(fields);
            error += success ? 0 : 1;
        }


        if (index >= 5) {
            fields = [];
            finish_and_pay_fields.forEach((item, key) => {
                fields.push(item.key);
            });
            success = await trigger(fields);
            error += success ? 0 : 1;
        }




        return (error === 0);
    }



    return <div className="app-page-sec">
        <div className="content">
            <Alert data={alertState} />
            <form>

                <RegisterTab
                    busy={busy}
                    onSelectTab={async (index) => {
                        return await validateField(index > 0 ? (index - 1) : index);
                    }}
                    onPrev={() => {
                        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop - 200, behavior: 'smooth' })
                    }}
                    onNext={async (index) => {
                        return await validateField(index);
                    }} onFinish={async (index) => {
                        var validate = await trigger();
                        console.log(errors)
                        if (validate) {
                            onSubmit(getValues())
                        }
                    }}
                >
                    <div title="Start" >
                        <h3>Start</h3>
                        <p>DFWLRRC reserves the right to refuse or deny any application.</p>
                        <div className="form-fields">

                            {start_info_fields && start_info_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    {item.type !== 'checkbox' && <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>}
                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.type === 'textarea' && <textarea resize="none" {...register(item.key, { required: item.required })} />}
                                    {item.type === 'checkbox' && <label className={"terms-checkbox " + (watch(item.key) ? "active" : "")}><input type="checkbox" {...register(item.key, { required: item.required })} />{item.label}&nbsp;{item.link}&nbsp;<span className="req">*</span></label>}
                                    {item.desc && <span className="desc pl-8">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                </div>
                            })}

                        </div>
                    </div>
                    <div title="Contact Information">
                        <h3>Contact Information</h3>
                        <div className="form-fields">
                            <h4 className="mb-3">Primary Applicant</h4>
                        </div>
                        <div className="form-fields form-flex">
                            {personal_info_fields && personal_info_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>

                                    <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>

                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.type === 'zip' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={zipFormatField} />}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'zip' && <span className="text-red-600">Invalid Zip Format</span>}
                                </div>
                            })}
                        </div>
                        <div className="form-fields">
                            <h4>Co-Applicant <span>(at same address)</span></h4>
                        </div>
                        <div className="form-fields">
                            {coApplicants_fields && coApplicants_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>

                                    <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>

                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                </div>
                            })}
                        </div>

                    </div>
                    <div title="Pet Ownership">
                        <h3>Pet Ownership</h3>
                        <div className="form-fields">
                            <h4 className="mb-5">Care</h4>
                        </div>
                        <div className="form-fields mb-4">
                            <div className="field-group col-span-2">
                                <label>Have you ever rehomed or surrendered a dog to a shelter, rescue or other individual? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("rehomed_dog", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['rehomed_dog'] && <span className="text-red-600">This field is required</span>}
                            </div>
                            {rehomed_dog === '1' && <div className="field-group col-span-2">
                                <label>If so, please tell us why? <span className="req">*</span></label>
                                <textarea resize="none" {...register("rehomed_dog_why", { required: true })} />
                                {errors['rehomed_dog_why'] && <span className="text-red-600">This field is required</span>}
                            </div>}
                            <div className="field-group col-span-2">
                                <label>Do you have a vet or have you had one for a prior dog? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("have_vet", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['have_vet'] && <span className="text-red-600">This field is required</span>}
                            </div>
                            {have_vet === '1' && <div className="field-group col-span-2">
                                <label>When was the last time you saw your vet? <span className="req">*</span></label>
                                <input type="text" {...register("last_time_saw_vet", { required: true })} />
                                {errors['last_time_saw_vet'] && <span className="text-red-600">This field is required</span>}
                            </div>}

                            {have_vet === '1' && <div className="field-group mb-3 col-span-2">
                                <label>May we contact him/her? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("contact_vet", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['contact_vet'] && <span className="text-red-600">This field is required</span>}
                                <span className="desc">Vet may be contacted by the rescue regarding the general veterinary care of current/previous pets such as vaccinations, HW preventation, and spay/neuter.</span>
                            </div>}
                        </div>

                        {have_vet === '1' && <div className="form-fields mb-5 col-3">

                            {vet_info_fields && vet_info_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    {item.type !== 'checkbox' && <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>}
                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.type === 'textarea' && <textarea resize="none" {...register(item.key, { required: item.required })} />}
                                    {item.type === 'checkbox' && <label className={"terms-checkbox " + (watch(item.key) ? "active" : "")}><input type="checkbox" {...register(item.key, { required: item.required })} />{item.label}&nbsp;{item.link}</label>}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                </div>
                            })}

                        </div>}

                        <div className="form-fields">
                            <div className="field-group col-span-2">
                                <label>What type of heartworm preventative do you give? <span className="req">*</span></label>
                                <input type="text"{...register("type_of_heartworm", { required: true })} />
                                {errors['type_of_heartworm'] && <span className="text-red-600">This field is required</span>}
                            </div>
                            <div className="field-group col-span-2">
                                <label>What brand of dog food do you feed? <span className="req">*</span></label>
                                <input type="text"{...register("brand_of_dog_food", { required: true })} />
                                {errors['brand_of_dog_food'] && <span className="text-red-600">This field is required</span>}
                            </div>
                        </div>
                        <div className="form-fields">
                            <h4>Current Dogs <span>Please list all dogs currently in residence</span></h4>
                            <div className="field-group col-span-2">
                                <label>Do you have current dogs? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("have_current_dogs", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['have_current_dogs'] && <span className="text-red-600">This field is required</span>}
                            </div>
                        </div>

                        {have_current_dogs === '1' && <MultipleFields classCols={"col-2"} addLabel="Add Another Current Dog" is_optional={true} ref={currentDogsRef} title="Current Dog"
                            data={currentDogs} fields={current_dogs_fields} onUpdateField={(key, field, value) => {
                                var data = currentDogs.map((a, k) => {
                                    if (key === k) {
                                        var n = Object.assign({}, a);
                                        n[field] = value;
                                        return n;
                                    }
                                    return a;
                                })
                                setCurrentDogs(data);
                            }}
                            onAdd={() => {
                                setCurrentDogs((state) => ([...state, defaultCurrentDogs]));
                            }}
                            onRemove={(index) => {
                                var data = currentDogs.filter((a, i) => {
                                    return i !== index;
                                })
                                setCurrentDogs(data);
                            }}
                        />}

                        <div className="form-fields">
                            <h4 className="col-span-2 mb-0">Past Dogs <span>Please list all dogs you were the primary caretaker of, but no longer have, and describe what happened to each.</span></h4>
                            <div className="field-group col-span-2">
                                <label>Do you have past dogs? <span className="req">*</span></label>
                                <div className="flex flex-row">
                                    {yesNo && yesNo.map((f, key) => {
                                        return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register("have_past_dogs", { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                    })}
                                </div>
                                {errors['have_past_dogs'] && <span className="text-red-600">This field is required</span>}
                            </div>
                        </div>

                        {have_past_dogs === '1' && <MultipleFields classCols={"col-2"} addLabel="Add Another Past Dog" is_optional={true} ref={pastDogsRef} title="Past Dog"
                            data={pastDogs} fields={past_dogs_fields} onUpdateField={(key, field, value) => {
                                var data = pastDogs.map((a, k) => {
                                    if (key === k) {
                                        var n = Object.assign({}, a);
                                        n[field] = value;
                                        return n;
                                    }
                                    return a;
                                })
                                setPastDogs(data);
                            }}
                            onAdd={() => {
                                setPastDogs((state) => ([...state, defaultPastDogs]));
                            }}
                            onRemove={(index) => {
                                var data = pastDogs.filter((a, i) => {
                                    return i !== index;
                                })
                                setPastDogs(data);
                            }}
                        />}

                        <div className="form-fields">
                            <h4>Other Pets</h4>
                        </div>
                        <div className="form-fields">
                            <div className="field-group col-span-2">
                                <label>Please list any other pets currently in residence</label>
                                <textarea resize="none" {...register("other_pets", { required: false })} />
                            </div>
                        </div>



                    </div>
                    <div title="Home Environment">
                        <h3>Home Environment</h3>
                        <div className="form-fields">
                            {environment_fields && environment_fields.filter(item => {
                                if (item.key === 'permission_to_have_large') {

                                    return own_rent === 'Rent';

                                }

                                if (item.key === 'fenced_off_separately') {

                                    return parseInt(swimming_pool) === 1
                                }

                                if (item)
                                    return true;
                            }).map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>

                                    {['list_ages_children'].indexOf(item.key) === -1 && <label>{item.label} {item.required === true && <span className="req">*</span>}</label>}
                                    {item.key === 'list_ages_children' && how_many_children > 0 && <label>{item.label} {item.required === true && <span className="req">*</span>}</label>}

                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        <option value="">Select</option>
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s}>{s}</option>;
                                        })}
                                    </select>}

                                    {['list_ages_children'].indexOf(item.key) === -1 && ['text', 'number'].indexOf(item.type) > -1 && <input min="0" type={item.type} {...register(item.key, { required: item.required, min: 0 })} />}
                                    {item.key === 'list_ages_children' && how_many_children > 0 && <input type={item.type} {...register(item.key, { required: item.required })} />}

                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}

                                    {item.type === 'radio' && <div className="flex flex-row">
                                        {item.data_source && item.data_source.map((f, key) => {
                                            return <label className="mr-3 force-font-normal radio-label" key={key}><input {...register(item.key, { required: true })} type="radio" value={f.value} /> {f.label}</label>
                                        })}
                                    </div>}


                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'min' && item.type === 'number' && <span className="text-red-600">Minimum value is invalid</span>}


                                </div>
                            })}
                        </div>
                    </div>

                    <div title="Dog Criteria">
                        <h3>Dog Criteria</h3>

                        <div className="form-fields">
                            {dog_criteria_fields && dog_criteria_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>

                                    {item.key === 'unwilling_behaviors_other' && unwilling_behaviors && unwilling_behaviors.indexOf('Other') !== -1 && <label>{item.label} {item.required === true && <span className="req">*</span>}</label>}
                                    {item.key !== 'unwilling_behaviors_other' && <label>{item.label} {item.required === true && <span className="req">*</span>}</label>}

                                    {item.type === 'checkbox' && <div className="flex flex-col">
                                        {item.data_source && item.data_source.map((v, k) => {
                                            return <label key={k} className="force-font-normal"><input type={item.type} value={v} {...register(item.key, { required: item.required })} /> {v}</label>
                                        })}
                                    </div>}
                                    {item.type === 'radio' && <div className="flex flex-row">
                                        {item.data_source && item.data_source.map((v, k) => {
                                            return <label key={k} className="mr-3 force-font-normal radio-label"><input type={item.type} value={v.value} {...register(item.key, { required: item.required })} /> {v.label}</label>
                                        })}
                                    </div>}
                                    {item.key !== 'unwilling_behaviors_other' && ['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.key === 'unwilling_behaviors_other' && unwilling_behaviors && unwilling_behaviors.indexOf('Other') !== -1 && ['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'textarea' && <textarea resize="none" {...register(item.key, { required: item.required })} />}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}

                                </div>
                            })}
                        </div>

                    </div>
                    <div title="Finish and Pay">
                        <h3>Finish and Pay</h3>
                        <div className="form-fields col-3">
                            {finish_and_pay_fields && finish_and_pay_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    <label>{item.label} {item.required === true && <span className="req">*</span>}</label>
                                    {item.type === 'select' && item.key === 'card_months' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.value}>{s.label}</option>;
                                        })}
                                    </select>}
                                    {item.type === 'select' && item.key === 'card_year' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {renderYears()}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input min={0} type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'textarea' && <textarea resize="none" {...register(item.key, { required: item.required })} />}

                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}

                                </div>
                            })}
                        </div>
                    </div>

                </RegisterTab>


            </form>
            <InlineLoader show={busy} />
        </div>
    </div>
}



const Sections = {
    Section1
}


export default Sections